import { PetTypes } from '@frontend/api-pet';
import { Icon, IconColorType } from '@frontend/icons';

type Props = {
  imageType: PetTypes.PetImageType; // Can be utilized as generalized type for more vertical types in future like for automobile e.g PetImageType | AutoMobileImageType
  size?: number; // Definition for image size
  color?: IconColorType; // Definition for image color
};

export const PetIcon = ({ imageType, size = 24, ...rest }: Props) => {
  /**
   * @desc: Function is defined to get the respective Icon JSX
   * @param imageType : PetImageType
   * @param size : number
   * @returns : JSX
   */
  const getIcon = (imageType: PetTypes.PetImageType, size?: number) => {
    switch (imageType) {
      case PetTypes.PetImageType.Dog:
        return <Icon name='vet-dog' size={size} {...rest} />;
      case PetTypes.PetImageType.Cat:
        return <Icon name='vet-cat' size={size} {...rest} />;
      case PetTypes.PetImageType.Bird:
        return <Icon name='vet-bird' size={size} {...rest} />;
      case PetTypes.PetImageType.LargeMammal:
      case PetTypes.PetImageType.Horse:
        return <Icon name='vet-horse' size={size} {...rest} />;
      case PetTypes.PetImageType.SmallMammal:
      case PetTypes.PetImageType.Mouse:
        return <Icon name='vet-rodent' size={size} {...rest} />;
      case PetTypes.PetImageType.Reptile:
      case PetTypes.PetImageType.Chameleon:
        return <Icon name='vet-reptile' size={size} {...rest} />;
      default:
        return <Icon name='vet-dog' size={size} {...rest} />;
    }
  };
  return getIcon(imageType, size);
};
